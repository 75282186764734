import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Proveedores = lazy(() => import('../../container/proveedores/Proveedores'));
const NuevoProveedor = lazy(() => import('../../container/proveedores/NuevoProveedor'));
const EditarProveedor = lazy(() => import('../../container/proveedores/EditarProveedor'));
const NuevoPrecio = lazy(() => import('../../container/proveedores/NuevoPrecio'));
const EditarPrecio = lazy(() => import('../../container/proveedores/EditarPrecio'));
const NuevaCotizacion = lazy(() => import('../../container/proveedores/NuevaCotizacion'));

const ProveedoresRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Proveedores} />
      <Route exact path={`${path}/nuevo`} component={NuevoProveedor} />
      <Route exact path={`${path}/:id`} component={EditarProveedor} />
      <Route exact path={`${path}/nuevo_precio/:idP/:idI`} component={NuevoPrecio} />
      <Route exact path={`${path}/editar_precio/:id`} component={EditarPrecio} />
      <Route exact path={`${path}/nueva_cotizacion/:id`} component={NuevaCotizacion} />
    </Switch>
  );
};

export default ProveedoresRoutes;