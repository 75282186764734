const actions = {
    UPDATE_DATA: 'UPDATE_DATA',
    CLEAR_DATA: 'CLEAR_DATA',

    updateData: data => {
        return {
            type: actions.UPDATE_DATA,
            data,
        };
    },

    clearData: () => {
        return {
            type: actions.CLEAR_DATA,
        };
    },
};

export default actions;
