import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ValoracionesPreQuirurgicas = lazy(() => import('../../container/valoracionesPreQuirurgicas/ValoracionesPreQuirurgicas'));
const NuevaValoracionPreQuirurgica = lazy(() => import('../../container/valoracionesPreQuirurgicas/NuevaValoracionPreQuirurgica'));
const EditarValoracionPreQuirurgica = lazy(() => import('../../container/valoracionesPreQuirurgicas/EditarValoracionPreQuirurgica'));

const ValoracionesPreQuirurgicasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ValoracionesPreQuirurgicas} />
      <Route exact path={`${path}/nueva/:id`} component={NuevaValoracionPreQuirurgica} />
      <Route exact path={`${path}/:id`} component={EditarValoracionPreQuirurgica} />
    </Switch>
  );
};

export default ValoracionesPreQuirurgicasRoutes;