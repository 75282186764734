import React from 'react';
import { Row, Col } from 'antd';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { LoginCardWrapper } from './style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh', backgroundColor: '#e2e6ef' }}>
        <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 14 }} xs={{ span: 22 }}>
          <LoginCardWrapper>
            <Cards headless>
              <WraperContent />
            </Cards>
          </LoginCardWrapper>
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
