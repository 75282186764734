import actions from './actions';
import { API } from '../../config/api/Api';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  CHECK_PREVIOUS_LOGIN_BEGIN,
  CHECK_PREVIOUS_LOGIN_SUCCESS,
  CHECK_PREVIOUS_LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR
} = actions;

const initState = {
  login: API.getAccessToken() != null,
  loading: false,
  error: null,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
        loading: false,
        error: null,
      };
    case LOGIN_ERR:
      return {
        ...state,
        login: false,
        loading: false,
        error: err,
      };

    case CHECK_PREVIOUS_LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CHECK_PREVIOUS_LOGIN_SUCCESS:
      return {
        ...state,
        login: true,
        loading: false,
        error: null,
      };
    case CHECK_PREVIOUS_LOGIN_ERR:
      return {
        ...state,
        login: false,
        loading: false,
        error: err,
      };

    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: false,
        loading: false,
        error: null,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };

    default:
      return state;
  }
};
export default AuthReducer;
