import axios from 'axios';
import { notification } from 'antd';
import { Sequentialize } from './Sequentialize';
import { getItem, setItem, removeItem } from '../../utility/localStorageControl';
import store from '../../redux/store';
import actions from '../../redux/authentication/actions';
const { logoutSuccess } = actions;

const STORAGE_ACCESS_TOKEN = 'access_token';
const STORAGE_REFRESH_TOKEN = 'refresh_token';
const STORAGE_USER_ID = 'user_id';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASE_URL
});
Sequentialize(instance);

class API {
    static refreshTimeOutId = null;
    static retires = 0;

    static authHeader = () => ({
        Authorization: `Bearer ${API.getAccessToken()}`,
    });

    static login(username, passowrd) {
        return instance.post(
            Endpoints.auth.login,
            { username: username, password: passowrd, grant_type: 'password' }
        ).then(response => {
            if (response.data.success) {
                API.saveToken(response.data.data);
                API.scheduleRefreshRequest(response.data.data.expires_in);
            } else {
                notification.error({
                    message: `Ha ocurrido un problema, #${response.data.error.code}`,
                    description: response.data.error.message,
                    placement: 'bottomRight',
                });
            }
            return Promise.resolve(response);
        })
    };

    static logout() {
        return instance.post(
            Endpoints.auth.logout,
            {},
            { headers: API.authHeader() }
        ).then(response => {
            if (response.data.success) {
                API.removeToken();
                API.freeRefreshRequest();
            } else {
                notification.error({
                    message: `Ha ocurrido un problema, #${response.data.error.code}`,
                    description: response.data.error.message,
                    placement: 'bottomRight',
                });
            }
            return Promise.resolve(response);
        })
    };

    static getNewToken(internalCall = true) {
        return instance.post(
            Endpoints.auth.token_refresh,
            { grant_type: 'refreshToken', refreshToken: API.getRefreshToken() },
            { headers: API.authHeader() }
        ).then((response) => {
            if (response.data.success) {
                API.saveToken(response.data.data);
                API.scheduleRefreshRequest(response.data.data.expires_in);
            }
            if (!response.data.success && internalCall) {
                store.dispatch(logoutSuccess());
                API.removeToken();
                API.freeRefreshRequest();
            } else {
                return Promise.resolve(response);
            }
        }).catch(err => {
            if (internalCall) {
                if (API.retires < 30) {
                    API.scheduleRefreshRequest(120);
                } else {
                    store.dispatch(logoutSuccess());
                    API.removeToken();
                    API.freeRefreshRequest();
                }
            } else {
                return Promise.reject(err);
            }
        });
    };

    static saveToken(data) {
        setItem(STORAGE_ACCESS_TOKEN, data.access_token);
        setItem(STORAGE_REFRESH_TOKEN, data.refreshToken);
        if (data.user_id) setItem(STORAGE_USER_ID, data.user_id);
        API.retires = 0;
    };

    static scheduleRefreshRequest(time) {
        API.retires++;
        API.refreshTimeOutId = window.setTimeout(
            API.getNewToken,
            time * 1000 / 2
        );
    };

    static freeRefreshRequest() {
        API.retires = 0;
        if (API.refreshTimeOutId) {
            window.clearTimeout(API.refreshTimeOutId);
        }
    };

    static removeToken() {
        removeItem(STORAGE_ACCESS_TOKEN);
        removeItem(STORAGE_REFRESH_TOKEN);
        removeItem(STORAGE_USER_ID);
    };

    static getAccessToken() { return getItem(STORAGE_ACCESS_TOKEN) };

    static getRefreshToken() { return getItem(STORAGE_REFRESH_TOKEN) };

    static getUserId() { return getItem(STORAGE_USER_ID) };

    static post(ruta, data = {}) {
        return instance.post(
            ruta,
            data, { headers: API.authHeader() }
        ).then(response => {
            if (!response.data.success) {
                notification.error({
                    message: `Ha ocurrido un problema, #${response.data.error.code}`,
                    description: response.data.error.message,
                    placement: 'bottomRight',
                });
            }
            return Promise.resolve(response);
        })
    };

    static postFile(ruta, data = {}) {
        return instance.post(
            ruta,
            data, { headers: API.authHeader(), responseType: 'blob', }
        ).then(response => {
            return Promise.resolve(response);
        })
    };
};

export { API };

/**
 * It's suggested to configure the RESTful endpoints in this file
 * so that there is only one source of truth, future update of endpoints
 * could be done from here without refactoring on multiple places throughout the app
 */
const Endpoints = {
    auth: {
        login: 'usuario/login',
        logout: 'usuario/logout',
        token_refresh: 'usuario/refrescar_token',
    },
    catalogo_cirugia: {
        capturar: 'catalogo_cirugia/capturar',
        modificar: 'catalogo_cirugia/modificar',
        listar: 'catalogo_cirugia/listar',
        ver: 'catalogo_cirugia/ver',
        cirugias_mensuales: 'catalogo_cirugia/cirugias_mensuales',
        cirugias_totales: 'catalogo_cirugia/cirugias_totales'
    },
    caprini: {
        capturar: 'caprini/capturar',
        informacion_capturar: 'caprini/informacion_capturar',
        modificar: 'caprini/modificar',
        ver: 'caprini/ver',
        pdf: 'caprini/pdf',
        descargar: 'caprini/descargar',
    },
    categoria_insumo: {
        capturar: 'categoria_insumo/capturar',
        modificar: 'categoria_insumo/modificar',
        listar: 'categoria_insumo/listar',
        ver: 'categoria_insumo/ver',
        habilitar: 'categoria_insumo/habilitar',
        deshabilitar: 'categoria_insumo/deshabilitar',
    },
    categoria_proveedor: {
        capturar: 'categoria_proveedor/capturar',
        modificar: 'categoria_proveedor/modificar',
        listar: 'categoria_proveedor/listar',
        ver: 'categoria_proveedor/ver',
        habilitar: 'categoria_proveedor/habilitar',
        deshabilitar: 'categoria_proveedor/deshabilitar',
    },
    cirugia: {
        capturar: 'cirugia/capturar',
        informacion_capturar: 'cirugia/informacion_capturar',
        modificar: 'cirugia/modificar',
        listar: 'cirugia/listar',
        ver: 'cirugia/ver',
        cerrar: 'cirugia/cerrar',
        cancelar: 'cirugia/cancelar',
        descargar_indicadores_quimicos: 'cirugia/descargar_indicadores_quimicos',
        descargar_valoracion_pre_anestesica: 'cirugia/descargar_valoracion_pre_anestesica',
        descargar_valoracion_pre_anestesica_externa: 'cirugia/descargar_valoracion_pre_anestesica_externa',
        descargar_registro_anestesia: 'cirugia/descargar_registro_anestesia',
        descargar_registro_anestesia_bloqueo: 'cirugia/descargar_registro_anestesia_bloqueo',
        descargar_registro_anestesia_general_inhalada: 'cirugia/descargar_registro_anestesia_general_inhalada',
        descargar_registro_anestesia_sedacion: 'cirugia/descargar_registro_anestesia_sedacion',
        descargar_registro_clinico_enfermeria_hoja1: 'cirugia/descargar_registro_clinico_enfermeria_hoja1',
        descargar_registro_clinico_enfermeria_hoja2: 'cirugia/descargar_registro_clinico_enfermeria_hoja2',
        descargar_registro_clinico_enfermeria_hoja3: 'cirugia/descargar_registro_clinico_enfermeria_hoja3',
        descargar_guia_transferencia_paciente: 'cirugia/descargar_guia_transferencia_paciente',
        descargar_reporte_quirurgico_enfermeria: 'cirugia/descargar_reporte_quirurgico_enfermeria',
        descargar_consumo_quirofano: 'cirugia/descargar_consumo_quirofano',
    },
    codigo_postal: {
        ver_clientes: 'codigo_postal/ver_clientes',
    },
    colonia: {
        listar: 'colonia/listar',
    },
    cotizacion_insumo: {
        capturar: 'cotizacion_insumo/capturar',
        informacion_capturar: 'cotizacion_insumo/informacion_capturar',
        informacion_capturar_precio: 'cotizacion_insumo/informacion_capturar_precio',
        modificar: 'cotizacion_insumo/modificar',
        listar: 'cotizacion_insumo/listar',
        ver: 'cotizacion_insumo/ver',
        cancelar: 'cotizacion_insumo/cancelar',
        rechazar: 'cotizacion_insumo/rechazar',
        aprobar: 'cotizacion_insumo/aprobar',
        comprar: 'cotizacion_insumo/comprar',
        cerrar: 'cotizacion_insumo/cerrar',
    },
    detalle_cirugia_catalogo: {
        capturar: 'detalle_cirugia_catalogo/capturar',
        eliminar: 'detalle_cirugia_catalogo/eliminar',
    },
    entrada_salida: {
        capturar: 'entrada_salida/capturar',
        capturar_cotizacion: 'entrada_salida/capturar_cotizacion',
        informacion_capturar: 'entrada_salida/informacion_capturar',
        modificar: 'entrada_salida/modificar',
        listar: 'entrada_salida/listar',
        ver: 'entrada_salida/ver',
        pdf: 'entrada_salida/pdf',
        descargar: 'entrada_salida/descargar',
        listar_reporte_caducidad: 'entrada_salida/listar_reporte_caducidad',
    },
    historia_clinica: {
        capturar: 'historia_clinica/capturar',
        informacion_capturar: 'historia_clinica/informacion_capturar',
        medicos_tratantes: 'historia_clinica/medicos_tratantes',
        modificar: 'historia_clinica/modificar',
        listar: 'historia_clinica/listar',
        ver: 'historia_clinica/ver',
        cerrar: 'historia_clinica/cerrar',
        pdf: 'historia_clinica/pdf',
        descargar: 'historia_clinica/descargar',
        copiar_anterior: 'historia_clinica/copiar_anterior',
    },
    ingrediente_activo: {
        capturar: 'ingrediente_activo/capturar',
        modificar: 'ingrediente_activo/modificar',
        listar: 'ingrediente_activo/listar',
        ver: 'ingrediente_activo/ver',
        habilitar: 'ingrediente_activo/habilitar',
        deshabilitar: 'ingrediente_activo/deshabilitar',
    },
    insumo: {
        capturar: 'insumo/capturar',
        informacion_capturar: 'insumo/informacion_capturar',
        modificar: 'insumo/modificar',
        listar: 'insumo/listar',
        ver: 'insumo/ver',
        habilitar: 'insumo/habilitar',
        deshabilitar: 'insumo/deshabilitar',
        pdf: 'insumo/pdf',
        descargar: 'insumo/descargar',
    },
    municipio: {
        listar: 'municipio/listar',
    },
    nota_egreso: {
        capturar: 'nota_egreso/capturar',
        informacion_capturar: 'nota_egreso/informacion_capturar',
        modificar: 'nota_egreso/modificar',
        ver: 'nota_egreso/ver',
        pdf: 'nota_egreso/pdf',
        descargar: 'nota_egreso/descargar',
    },
    nota_ingreso: {
        capturar: 'nota_ingreso/capturar',
        informacion_capturar: 'nota_ingreso/informacion_capturar',
        modificar: 'nota_ingreso/modificar',
        ver: 'nota_ingreso/ver',
        pdf: 'nota_ingreso/pdf',
        descargar: 'nota_ingreso/descargar',
    },
    nota_post_operatoria: {
        capturar: 'nota_post_operatoria/capturar',
        informacion_capturar: 'nota_post_operatoria/informacion_capturar',
        modificar: 'nota_post_operatoria/modificar',
        ver: 'nota_post_operatoria/ver',
        pdf: 'nota_post_operatoria/pdf',
        descargar: 'nota_post_operatoria/descargar',
    },
    nota_post_operatoria_externa: {
        capturar: 'nota_post_operatoria_externa/capturar',
        informacion_capturar: 'nota_post_operatoria_externa/informacion_capturar',
        modificar: 'nota_post_operatoria_externa/modificar',
        ver: 'nota_post_operatoria_externa/ver',
        pdf: 'nota_post_operatoria_externa/pdf',
        descargar: 'nota_post_operatoria_externa/descargar',
        pdf_blanco: 'nota_post_operatoria_externa/pdf_blanco',
    },
    paciente: {
        capturar: 'paciente/capturar',
        modificar: 'paciente/modificar',
        listar: 'paciente/listar',
        ver: 'paciente/ver',
        consultar_sexo: 'paciente/consultar_sexo',
        habilitar: 'paciente/habilitar',
        deshabilitar: 'paciente/deshabilitar',
    },
    permiso: {
        listar: 'permiso/listar',
    },
    permiso_rol: {
        capturar: 'permiso_rol/capturar',
        listar: 'permiso_rol/listar',
        eliminar: 'permiso_rol/eliminar',
    },
    permiso_usuario: {
        capturar: 'permiso_usuario/capturar',
        listar: 'permiso_usuario/listar',
        eliminar: 'permiso_usuario/eliminar',
    },
    precio_proveedor_insumo: {
        capturar: 'precio_proveedor_insumo/capturar',
        informacion_capturar: 'precio_proveedor_insumo/informacion_capturar',
        modificar: 'precio_proveedor_insumo/modificar',
        listar_proveedor: 'precio_proveedor_insumo/listar_proveedor',
        listar_insumo: 'precio_proveedor_insumo/listar_insumo',
        listar_precio_proveedor: 'precio_proveedor_insumo/listar_precio_proveedor',
        ver_precio_proveedor: 'precio_proveedor_insumo/ver_precio_proveedor',
        ver: 'precio_proveedor_insumo/ver',
        habilitar: 'precio_proveedor_insumo/habilitar',
        deshabilitar: 'precio_proveedor_insumo/deshabilitar',
    },
    proveedor: {
        capturar: 'proveedor/capturar',
        informacion_capturar: 'proveedor/informacion_capturar',
        modificar: 'proveedor/modificar',
        listar: 'proveedor/listar',
        ver: 'proveedor/ver',
        habilitar: 'proveedor/habilitar',
        deshabilitar: 'proveedor/deshabilitar',
    },
    resumen_quirurgico: {
        capturar: 'resumen_quirurgico/capturar',
        informacion_capturar: 'resumen_quirurgico/informacion_capturar',
        modificar: 'resumen_quirurgico/modificar',
        ver: 'resumen_quirurgico/ver',
        pdf: 'resumen_quirurgico/pdf',
        descargar: 'resumen_quirurgico/descargar',
    },
    rol: {
        capturar: 'rol/capturar',
        informacion_capturar: 'rol/informacion_capturar',
        modificar: 'rol/modificar',
        listar: 'rol/listar',
        ver: 'rol/ver',
    },
    tratamiento: {
        capturar: 'tratamiento/capturar',
        modificar: 'tratamiento/modificar',
        eliminar: 'tratamiento/eliminar',
    },
    usuario: {
        capturar: 'usuario/capturar',
        informacion_capturar: 'usuario/informacion_capturar',
        menu: 'usuario/menu',
        modificar: 'usuario/modificar',
        listar: 'usuario/listar',
        habilitar: 'usuario/habilitar',
        deshabilitar: 'usuario/deshabilitar',
        ver: 'usuario/ver',
        desbloquear: 'usuario/desbloquear',
        desconectar: 'usuario/desconectar',
        contrasenia: 'usuario/contrasenia',
    },
    valoracion_pre_quirurgica: {
        capturar: 'valoracion_pre_quirurgica/capturar',
        informacion_capturar: 'valoracion_pre_quirurgica/informacion_capturar',
        modificar: 'valoracion_pre_quirurgica/modificar',
        ver: 'valoracion_pre_quirurgica/ver',
        pdf: 'valoracion_pre_quirurgica/pdf',
        descargar: 'valoracion_pre_quirurgica/descargar',
    },
    valoracion_pre_quirurgica_2: {
        capturar: 'valoracion_pre_quirurgica_2/capturar',
        informacion_capturar: 'valoracion_pre_quirurgica_2/informacion_capturar',
        modificar: 'valoracion_pre_quirurgica_2/modificar',
        ver: 'valoracion_pre_quirurgica_2/ver',
        pdf: 'valoracion_pre_quirurgica_2/pdf',
        descargar: 'valoracion_pre_quirurgica_2/descargar',
    },
    /*
    fotografia: {
        capturar: 'fotografia/capturar',
        modificar: 'fotografia/modificar',
        listar: 'fotografia/listar',
    },*/
};

export { Endpoints };