import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducers';

const store = createStore(rootReducer, compose(applyMiddleware(thunk.withExtraArgument())));
store.subscribe(() => {
  // AL final esto hay que quitarlo
  //console.log(store.getState());
});
export default store;
