import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const CategoriasProveedores = lazy(() => import('../../container/categoriasProveedores/CategoriasProveedores'));
const NuevaCategoriaProveedor = lazy(() => import('../../container/categoriasProveedores/NuevaCategoriaProveedor'));
const EditarCategoriaProveedor = lazy(() => import('../../container/categoriasProveedores/EditarCategoriaProveedor'));

const CategoriasProveedoresRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={CategoriasProveedores} />
      <Route exact path={`${path}/nuevo`} component={NuevaCategoriaProveedor} />
      <Route exact path={`${path}/:id`} component={EditarCategoriaProveedor} />
    </Switch>
  );
};

export default CategoriasProveedoresRoutes;