import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const PreciosProveedoresInsumos = lazy(() => import('../../container/preciosProveedoresInsumos/PreciosProveedoresInsumos'));
const PreciosProveedores = lazy(() => import('../../container/preciosProveedoresInsumos/PreciosProveedores'));
const PreciosInsumos = lazy(() => import('../../container/preciosProveedoresInsumos/PreciosInsumos'));

const PreciosProveedoresInsumosRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={PreciosProveedoresInsumos} />
      <Route exact path={`${path}/:id`} component={PreciosProveedores} />
      <Route exact path={`${path}/:id`} component={PreciosInsumos} />
    </Switch>
  );
};

export default PreciosProveedoresInsumosRoutes;