import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Caprinis = lazy(() => import('../../container/caprinis/Caprinis'));
const NuevoCaprini = lazy(() => import('../../container/caprinis/NuevoCaprini'));
const EditarCaprini = lazy(() => import('../../container/caprinis/EditarCaprini'));

const CaprinisRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Caprinis} />
      <Route exact path={`${path}/nuevo/:id`} component={NuevoCaprini} />
      <Route exact path={`${path}/:id`} component={EditarCaprini} />
    </Switch>
  );
};

export default CaprinisRoutes;