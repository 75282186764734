import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotasIngreso = lazy(() => import('../../container/notasIngreso/NotasIngreso'));
const NuevaNotaIngreso = lazy(() => import('../../container/notasIngreso/NuevaNotaIngreso'));
const EditarNotaIngreso = lazy(() => import('../../container/notasIngreso/EditarNotaIngreso'));

const NotasIngresoRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={NotasIngreso} />
      <Route exact path={`${path}/nueva/:id`} component={NuevaNotaIngreso} />
      <Route exact path={`${path}/:id`} component={EditarNotaIngreso} />
    </Switch>
  );
};

export default NotasIngresoRoutes;