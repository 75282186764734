import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Insumos = lazy(() => import('../../container/insumos/Insumos'));
const NuevoInsumo = lazy(() => import('../../container/insumos/NuevoInsumo'));
const EditarInsumo = lazy(() => import('../../container/insumos/EditarInsumo'));
const NuevoPrecio = lazy(() => import('../../container/insumos/NuevoPrecio'));
const EditarPrecio = lazy(() => import('../../container/insumos/EditarPrecio'));
const NuevaCotizacion = lazy(() => import('../../container/insumos/NuevaCotizacion'));

const InsumosRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Insumos} />
      <Route exact path={`${path}/nuevo`} component={NuevoInsumo} />
      <Route exact path={`${path}/:id`} component={EditarInsumo} />
      <Route exact path={`${path}/nuevo_precio/:idI/:idP`} component={NuevoPrecio} />
      <Route exact path={`${path}/editar_precio/:id`} component={EditarPrecio} />
      <Route exact path={`${path}/nueva_cotizacion/:id`} component={NuevaCotizacion} />
    </Switch>
  );
};

export default InsumosRoutes;