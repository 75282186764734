import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotasEgreso = lazy(() => import('../../container/notasEgreso/NotasEgreso'));
const NuevaNotaEgreso = lazy(() => import('../../container/notasEgreso/NuevaNotaEgreso'));
const EditarNotaEgreso = lazy(() => import('../../container/notasEgreso/EditarNotaEgreso'));

const NotasEgresoRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={NotasEgreso} />
      <Route exact path={`${path}/nueva/:id`} component={NuevaNotaEgreso} />
      <Route exact path={`${path}/:id`} component={EditarNotaEgreso} />
    </Switch>
  );
};

export default NotasEgresoRoutes;