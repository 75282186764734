import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ResumenesQuirurgicos = lazy(() => import('../../container/resumenesQuirurgicos/ResumenesQuirurgicos'));
const NuevoResumenQuirurgico = lazy(() => import('../../container/resumenesQuirurgicos/NuevoResumenQuirurgico'));
const EditarResumenQuirurgico  = lazy(() => import('../../container/resumenesQuirurgicos/EditarResumenQuirurgico'));

const ResumenesQuirurgicosRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ResumenesQuirurgicos} />
      <Route exact path={`${path}/nuevo/:id`} component={NuevoResumenQuirurgico} />
      <Route exact path={`${path}/:id`} component={EditarResumenQuirurgico} />
    </Switch>
  );
};

export default ResumenesQuirurgicosRoutes;