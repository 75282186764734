const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  CHECK_PREVIOUS_LOGIN_BEGIN: 'CHECK_PREVIOUS_LOGIN_BEGIN',
  CHECK_PREVIOUS_LOGIN_SUCCESS: 'CHECK_PREVIOUS_LOGIN_SUCCESS',
  CHECK_PREVIOUS_LOGIN_ERR: 'CHECK_PREVIOUS_LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  loginBegin: () => {
    return {
      type: actions.LOGIN_BEGIN,
    };
  },

  loginSuccess: () => {
    return {
      type: actions.LOGIN_SUCCESS,
    };
  },

  loginErr: err => {
    return {
      type: actions.LOGIN_ERR,
      err,
    };
  },

  checkPreviousLoginBegin: () => {
    return {
      type: actions.CHECK_PREVIOUS_LOGIN_BEGIN,
    };
  },

  checkPreviousLoginSuccess: () => {
    return {
      type: actions.CHECK_PREVIOUS_LOGIN_SUCCESS,
    };
  },

  checkPreviousLoginErr: err => {
    return {
      type: actions.CHECK_PREVIOUS_LOGIN_ERR,
      err,
    };
  },

  logoutBegin: () => {
    return {
      type: actions.LOGOUT_BEGIN,
    };
  },

  logoutSuccess: () => {
    return {
      type: actions.LOGOUT_SUCCESS,
    };
  },

  logoutErr: err => {
    return {
      type: actions.LOGOUT_ERR,
      err,
    };
  },
};

export default actions;
