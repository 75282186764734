import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotasPostOperatoriasExternas = lazy(() => import('../../container/notasPostOperatoriasExternas/NotasPostOperatoriasExternas'));
const NuevaNotaPostOperatoriaExterna = lazy(() => import('../../container/notasPostOperatoriasExternas/NuevaNotaPostOperatoriaExterna'));
const EditarNotaPostOperatoriaExterna = lazy(() => import('../../container/notasPostOperatoriasExternas/EditarNotaPostOperatoriaExterna'));

const NotasPostOperatoriasExternasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={NotasPostOperatoriasExternas} />
      <Route exact path={`${path}/nueva/:id`} component={NuevaNotaPostOperatoriaExterna} />
      <Route exact path={`${path}/:id`} component={EditarNotaPostOperatoriaExterna} />
    </Switch>
  );
};

export default NotasPostOperatoriasExternasRoutes;