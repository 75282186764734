import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const CategoriasInsumos = lazy(() => import('../../container/categoriasInsumos/CategoriasInsumos'));
const NuevaCategoriaInsumo = lazy(() => import('../../container/categoriasInsumos/NuevaCategoriaInsumo'));
const EditarCategoriaInsumo = lazy(() => import('../../container/categoriasInsumos/EditarCategoriaInsumo'));

const CategoriasInsumosRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={CategoriasInsumos} />
      <Route exact path={`${path}/nuevo`} component={NuevaCategoriaInsumo} />
      <Route exact path={`${path}/:id`} component={EditarCategoriaInsumo} />
    </Switch>
  );
};

export default CategoriasInsumosRoutes;