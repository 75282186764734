import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const CatalogosCirugias = lazy(() => import('../../container/catalogosCirugias/CatalogosCirugias'));
const NuevoCatalogoCirugias = lazy(() => import('../../container/catalogosCirugias/NuevoCatalogoCirugias'));
const EditarCatalogoCirugias = lazy(() => import('../../container/catalogosCirugias/EditarCatalogoCirugias'));

const CatalogosCirugiasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={CatalogosCirugias} />
      <Route exact path={`${path}/nuevo`} component={NuevoCatalogoCirugias} />
      <Route exact path={`${path}/:id`} component={EditarCatalogoCirugias} />
    </Switch>
  );
};

export default CatalogosCirugiasRoutes;