import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const NotasPostOperatorias = lazy(() => import('../../container/notasPostOperatorias/NotasPostOperatorias'));
const NuevaNotaPostOperatoria = lazy(() => import('../../container/notasPostOperatorias/NuevaNotaPostOperatoria'));
const EditarNotaPostOperatoria = lazy(() => import('../../container/notasPostOperatorias/EditarNotaPostOperatoria'));

const NotasPostOperatoriasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={NotasPostOperatorias} />
      <Route exact path={`${path}/nueva/:id`} component={NuevaNotaPostOperatoria} />
      <Route exact path={`${path}/:id`} component={EditarNotaPostOperatoria} />
    </Switch>
  );
};

export default NotasPostOperatoriasRoutes;