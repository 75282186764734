import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Cirugias = lazy(() => import('../../container/cirugias/Cirugias'));
const NuevaCirugia = lazy(() => import('../../container/cirugias/NuevaCirugia'));
const EditarCirugia = lazy(() => import('../../container/cirugias/EditarCirugia'));

const CirugiasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Cirugias} />
      <Route exact path={`${path}/nuevo`} component={NuevaCirugia} />
      <Route exact path={`${path}/:id`} component={EditarCirugia} />
    </Switch>
  );
};

export default CirugiasRoutes;