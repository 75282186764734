import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const HistoriasClinicas = lazy(() => import('../../container/historiasClinicas/HistoriasClinicas'));
const NuevaHistoriaClinica = lazy(() => import('../../container/historiasClinicas/NuevaHistoriaClinica'));
const EditarHistoriaClinica = lazy(() => import('../../container/historiasClinicas/EditarHistoriaClinica'));

const HistoriasClinicasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={HistoriasClinicas} />
      <Route exact path={`${path}/nuevo/:id`} component={NuevaHistoriaClinica} />
      <Route exact path={`${path}/:id`} component={EditarHistoriaClinica} />
    </Switch>
  );
};

export default HistoriasClinicasRoutes;