import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const EntradasSalidas = lazy(() => import('../../container/entradasSalidas/EntradasSalidas'));
const NuevaEntradaSalida = lazy(() => import('../../container/entradasSalidas/NuevaEntradaSalida'));
const EditarEntradaSalida = lazy(() => import('../../container/entradasSalidas/EditarEntradaSalida'));
const ReporteCaducidades = lazy(() => import('../../container/entradasSalidas/ReporteCaducidades'));

const EntradasSalidasRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={EntradasSalidas} />
      <Route exact path={`${path}/nueva/:id`} component={NuevaEntradaSalida} />
      <Route exact path={`${path}/:id/:ant`} component={EditarEntradaSalida} />
      <Route exact path={`${path}/caducidades`} component={ReporteCaducidades} />
    </Switch>
  );
};

export default EntradasSalidasRoutes;