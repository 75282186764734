import actions from './actions';

const {
    UPDATE_DATA,
    CLEAR_DATA
} = actions;

const initState = {};

const FormCaptureReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case UPDATE_DATA:
            return {
                ...state,
                ...data
            };

        case CLEAR_DATA:
            return {};

        default:
            return state;
    }
};

export default FormCaptureReducer;