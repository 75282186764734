import Styled from 'styled-components';

const LoginCardWrapper = Styled.div`
    .ant-card {
        border-radius: 25px;
        box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.2);
    }

    h1 {
        color: #28285d;
    }

    h2 {
        color: #bababa;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;

export { LoginCardWrapper };