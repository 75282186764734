import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const CotizacionesInsumos = lazy(() => import('../../container/cotizacionesInsumos/CotizacionesInsumos'));
const NuevaCotizacion = lazy(() => import('../../container/cotizacionesInsumos/NuevaCotizacion'));
const EditarCotizacion = lazy(() => import('../../container/cotizacionesInsumos/EditarCotizacion'));

const CotizacionesInsumosRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={CotizacionesInsumos} />
      <Route exact path={`${path}/nueva`} component={NuevaCotizacion} />
      <Route exact path={`${path}/:id/:ant`} component={EditarCotizacion} />
    </Switch>
  );
};

export default CotizacionesInsumosRoutes;