import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const IngredientesActivos = lazy(() => import('../../container/ingredientesActivos/IngredientesActivos'));
const NuevoIngredienteActivo = lazy(() => import('../../container/ingredientesActivos/NuevoIngredienteActivo'));
const EditarIngredienteActivo = lazy(() => import('../../container/ingredientesActivos/EditarIngredienteActivo'));

const IngredientesActivosRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={IngredientesActivos} />
      <Route exact path={`${path}/nuevo`} component={NuevoIngredienteActivo} />
      <Route exact path={`${path}/:id`} component={EditarIngredienteActivo} />
    </Switch>
  );
};

export default IngredientesActivosRoutes;