import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Usuarios = lazy(() => import('../../container/usuarios/Usuarios'));
const NuevoUsuario = lazy(() => import('../../container/usuarios/NuevoUsuario'));
const EditarUsuario = lazy(() => import('../../container/usuarios/EditarUsuario'));

const UsuariosRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Usuarios} />
      <Route exact path={`${path}/nuevo`} component={NuevoUsuario} />
      <Route exact path={`${path}/:id`} component={EditarUsuario} />
    </Switch>
  );
};

export default UsuariosRoutes;