import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ValoracionesPreQuirurgicas2 = lazy(() => import('../../container/valoracionesPreQuirurgicas2/ValoracionesPreQuirurgicas2'));
const NuevaValoracionPreQuirurgica2 = lazy(() => import('../../container/valoracionesPreQuirurgicas2/NuevaValoracionPreQuirurgica2'));
const EditarValoracionPreQuirurgica2 = lazy(() => import('../../container/valoracionesPreQuirurgicas2/EditarValoracionPreQuirurgica2'));

const ValoracionesPreQuirurgicas2Routes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ValoracionesPreQuirurgicas2} />
      <Route exact path={`${path}/nueva/:id`} component={NuevaValoracionPreQuirurgica2} />
      <Route exact path={`${path}/:id`} component={EditarValoracionPreQuirurgica2} />
    </Switch>
  );
};

export default ValoracionesPreQuirurgicas2Routes;