import actions from './actions';
import { API } from '../../config/api/Api';
//import { fijarAjustes } from '../ajustes/actionCreator';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  checkPreviousLoginBegin,
  checkPreviousLoginSuccess,
  checkPreviousLoginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr
} = actions;

const login = (username, password) => {
  return async dispatch => {
    dispatch(loginBegin());
    API.login(username, password).then(response => {
      if (response.data.success) {
        dispatch(loginSuccess());
        //dispatch(fijarAjustes(response.data.data.ajusteTienda))
      } else {
        dispatch(loginErr(response.data.error.message));
      }
    }).catch(err => {
      dispatch(loginErr(err));
    })
  };
};

const checkPreviousLogin = () => {
  return async dispatch => {
    if (API.getAccessToken() != null) {
      dispatch(checkPreviousLoginBegin());
      API.freeRefreshRequest();
      API.getNewToken(false).then(response => {
        if (response.data.success) {
          dispatch(checkPreviousLoginSuccess());
        } else {
          dispatch(checkPreviousLoginErr(response.data.error.message));
          API.removeToken();
        }
      }).catch(err => {
        dispatch(checkPreviousLoginErr(err));
        API.removeToken();
      })
    }
  };
};

const logOut = () => {
  return async dispatch => {
    dispatch(logoutBegin());
    API.logout().then(response => {
      dispatch(logoutSuccess());
    }).catch(err => {
      dispatch(logoutErr(err));
    })
  };
};

export { login, checkPreviousLogin, logOut };
